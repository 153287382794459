/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { Box, jsx } from 'theme-ui'
import Modal from '@/components/Commons/Modal'
import { useModalDispatch, useModalState } from '@/contexts/modal'
import { useIsMobile } from '@/utils/useIsMobile'

import { Header } from '../index'

const styles = {
  container: {
    position: 'relative',
    minHeight: '100vh',
    background: '#191A19',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}

const WithMainLayout = (Page) =>
  function Component(props) {
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { layout } = props?.pageProps

    const isMobile = useIsMobile()
    const modalState = useModalState()
    const modalDispatch = useModalDispatch()

    const pageProps = { ...props, isMobile }

    return (
      <main sx={styles.container}>
        <Box sx={styles.content}>
          {layout?.header && <Header {...layout?.header} isMobile={isMobile} />}
          <Page {...pageProps} />
          <Modal
            show={modalState !== null}
            onClose={() => modalDispatch({ type: 'hide' })}
          >
            {modalState}
          </Modal>
        </Box>
        <div data-iframe-height="1"></div>
      </main>
    )
  }

export default WithMainLayout
