import closedContent from '@/constants/contents/layout/closed.json'
import { getIsSsrMobile } from 'utils/getIsSsrMobile'
import WithMainLayout from '../../../components/hocs'
import { LOCALES } from '@/constants/config/locales'
import Closed from '@/components/Pages/Closed'

export default WithMainLayout(Closed)

export async function getStaticPaths() {
  const paths = LOCALES.map((locale) => ({
    params: { locale: locale },
  }))

  return {
    paths: paths,
    fallback: false,
  }
}

export const getStaticProps = async (ctx) => {
  const { locale } = ctx?.params

  return {
    props: {
      locale,
      layout: closedContent[locale] || null,
      header: {
        logo: closedContent[locale]?.landing?.mobileHeaderImage || null,
        src: closedContent[locale]?.landing?.desktopHeaderImage || null,
      },
      isSsrMobile: getIsSsrMobile(ctx),
    },
  }
}
