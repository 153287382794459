import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Image from 'next/image'

const ImageNxt = React.forwardRef(
  (
    {
      src,
      width = '100%',
      height = '25%',
      imageStyle,
      layout = 'responsive',
      styles,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        sx={{
          width: width,
          height: height,
          variant: `box.${imageStyle}`,
          ...styles,
        }}
      >
        <Image
          width={width}
          height={height}
          sx={{
            variant: `images.${imageStyle}`,
          }}
          layout={layout}
          src={src}
          ref={ref}
          {...props}
        />
      </div>
    )
  },
)
export default ImageNxt
