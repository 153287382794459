/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Image from '@/components/Commons/Image'
import { useRouter } from 'next/router'

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'white',
  },
  fullWidth: {
    width: '100%',
  },
}

export const Header = ({ logo, src, isMobile, ...props }) => {
  let width
  if (typeof window !== 'undefined') width = window.innerWidth

  return (
    <header sx={styles.header}>
      {isMobile && logo && (
        <Image {...props} src={logo} height={width} width={width} />
      )}
      {!isMobile && src && (
        <Image width="100%" height="33%" {...props} src={src} />
      )}
    </header>
  )
}
