import React from 'react'
export function OGTags({ url, type, title, description, image, card }) {
  return (
    <>
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content={card} />
    </>
  )
}
