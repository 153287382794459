import React from 'react'

export const GTMIframe = ({ tags }) => {
  return (
    <>
      {tags && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${tags[0]}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}
    </>
  )
}
