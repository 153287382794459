import React from 'react'
import Box from '../../Commons/Box'
import styles from './styles'

const Closed = ({ pageProps }) => {
    const { layout } = pageProps
  
    return (
      <Box sx={styles.closed}>
        <Box>{layout?.message}</Box>
      </Box>
    )
}

export default Closed