export default {
    closed: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        height: '50vh',
        justifyContent: 'center',
        textAlign: 'center',
        variant: 'text.largeBold',
        width: '100%',
    },
}