/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import ReactDOM from 'react-dom'
import Box from '../Box'
import styles from './styles'

const Modal = React.forwardRef(({ title, onClose, children, sx }, ref) =>
  children !== null && children !== undefined && typeof document !== 'undefined'
    ? ReactDOM.createPortal(
        <React.Fragment ref={ref}>
          <Box sx={styles.modalOverlayShow} onClick={onClose} />
          <Box sx={{ ...styles.modalWrapper, ...sx }} data-testid="modal">
            <div sx={styles.modal}>
              <div sx={styles.modalHeader}>{title}</div>
              {children}
            </div>
          </Box>
        </React.Fragment>,
        document.body,
      )
    : null,
)

export default Modal
