/* eslint-disable prettier/prettier */
import React from 'react'
export const GTAG = ({ tags }) => {
  return (
    <>
      {tags && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${tags[0]}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          ${tags
            .map(
              (tag) => `gtag('config', '${tag}', {
            page_path: window.location.pathname
          });`,
            )
            .join(',')
            .replace(';,', '; ')}
        `,
            }}
          />
        </>
      )}
    </>
  )
}
