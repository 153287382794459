export default {
  modalOverlayShow: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'background',
    zIndex: '2',
    opacity: 0.75,
  },
  modalWrapper: {
    display: 'block',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['80%', '80%', '55%'],
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: 'background',
    zIndex: '3',
    padding: '30px',
  },
  modalHeader: {
    variant: 'text.mediumBold',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'background',
    color: 'textLight',
    fontFamily: 'bold',
    fontWeight: '40px',
    marginBottom: '10px',
  },
  modal: {
    height: '100%',
  },
}
