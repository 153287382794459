export { AC } from './scripts/AC'
export { FacebookPixel } from './scripts/facebook/Pixel'
export { HotjarPixel } from './scripts/hotjar/Pixel'
export { GTAG } from './scripts/google/GTAG'
export { GTMIframe } from './scripts/google/GTMIframe'
export { GTM } from './scripts/google/GTM'
export { TwitterPixel } from './scripts/twitter/Pixel'
export { OGTags } from './scripts/OGTags'

export { Header } from './layout/Header'
export { Footer } from './layout/Footer'
