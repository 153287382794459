import { useContext } from 'react'
import { IsSsrMobileContext } from '@/contexts/isMobile'
import { useWindowSize } from './useWindowSize'
export const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext)
  const { width: windowWidth } = useWindowSize()
  const isBrowserMobile = !!windowWidth && windowWidth < 768

  return isSsrMobile || isBrowserMobile
}
